import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				О нас | Bliss Tours
			</title>
			<meta name={"description"} content={"Раскрытие рассказов о исследовании - ваш паспорт в незабываемые путешествия"} />
			<meta property={"og:title"} content={"О нас | Bliss Tours"} />
			<meta property={"og:description"} content={"Раскрытие рассказов о исследовании - ваш паспорт в незабываемые путешествия"} />
			<meta property={"og:image"} content={"https://aviertop.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://aviertop.live/img/5333722.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://aviertop.live/img/5333722.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://aviertop.live/img/5333722.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://aviertop.live/img/5333722.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://aviertop.live/img/5333722.png"} />
			<meta name={"msapplication-TileImage"} content={"https://aviertop.live/img/5333722.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://aviertop.live/img/3.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="660px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h1"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					О нас
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
					Взгляд на повествование о странном блаженстве
				</Text>
				<Text font="--base">
					Добро пожаловать в сердце блаженства, где каждая страница нашей истории разворачивается с духом исследования.Как туристическое агентство, мы не просто о направлениях - мы о создании повествований о открытии.Наша история является свидетельством радости блуждания, острых ощущений приключений и магии создания воспоминаний, которые длится всю жизнь.
				</Text>
			</Box>
		</Section>
		<Section padding="120px 0 140px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-4">
			<Text margin="0px 0px 0px 0px" font="normal 700 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
				В Bliss Tours наша сущность формируется:
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="50px 0px 0px 0px"
				md-flex-direction="column"
				sm-margin="30px 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					margin="0px 10% 0px 0px"
					padding="4px 0px 0px 0px"
					md-width="100%"
					md-margin="0px 10% 50px 0px"
					sm-padding="0 0px 0px 0px"
					sm-margin="0px 10% 35px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						Страсть к исследованию - наше путешествие началось со страсти к исследованию и убеждения в преобразующей силе путешествий.Каждая поездка - это возможность узнать красоту мира и богатство разнообразных культур.
					</Text>
					<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						Разнообразные путешествия - независимо от того, ищете ли вы спокойствие природы, очарования исторических мест или вибрации шумных городов, Bliss Tours предлагает разнообразные путешествия.Наши маршруты созданы вкусом каждого странника.
					</Text>
					<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						Сообщество Wanderer-помимо того, чтобы быть туристическим агентством, Wander Bliss-это сообщество единомышленников.Мы празднуем радость общего опыта, способствуя связям, которые выходят за рамки направлений, которые мы исследуем.
					</Text>
					<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						Глобальная сеть - наша обширная сеть экспертов и партнеров по путешествиям охватывает глобус.Эта сеть гарантирует, что каждое путешествие будет внедрено местными знаниями и опытом, что делает ваше приключение подлинным и запоминающимся.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://aviertop.live/img/4.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://aviertop.live/img/5.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text margin="20px 0px 0px 0px" font="normal 700 32px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
					Присоединяйтесь к Bliss Tour
				</Text>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Поскольку мы продолжаем добавлять главы в нашу историю, мы приглашаем вас присоединиться к Odyssey Odyssey Wander Bliss.Каждая поездка - это глава, ожидающая написания, холст, готовый к вашему уникальному опыту.Откройте для нас мир, где каждое путешествие - это история исследования.			</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});